<template>
  <div class="shelve_goods_management">
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div class="condition-row">
        <el-form-item label="行政区划：" label-width="70px">
          <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" @change="bizZoneChange" @loadOver="areaIdLoadOver()" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :clearable="false" />
        </el-form-item>
        <el-form-item label="机构名称：" label-width="70px">
          <el-input class="zwx-input" style="width:220px !important" v-model.trim="msForm.unitNameLike" placeholder="" clearable />
        </el-form-item>
        <el-form-item label="状态：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.ifEnable">
            <el-checkbox class="zwx-checkbox" :label="1">启用</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="0">停用</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div style="clear: both" />
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryShelveGoodsList(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add">
        添加
      </el-button>
    </div>

    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="rid" :data="msForm.tableList" border stripe tooltip-effect="light">
      <el-table-column prop="busZoneAreaExp" label="行政区划" width="260" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.busZoneAreaExp?.fullName?.replaceAll('_', '/') || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="unitName" label="机构名称" width="260" header-align="center" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.unitName || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="busZoneCodeAddr" label="经营地址" width="280" header-align="center" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.busZoneCodeAddr || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="legalPerson" label="法定代表人" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.legalPerson || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="linkPerson" label="联系人" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.linkPerson || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="desensitiUnitContact" label="联系电话" width="128" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.desensitiUnitContact || '-' }}</span>
        </template>
      </el-table-column>
      <!--
      <el-table-column prop="businessType" label="商户类型" width="100" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ { '04': '微信', '01': '支付宝', '05': '银联商务' }[scope.row.businessType] || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="原价(元)" width="120" header-align="center" align="center" show-overflow-tooltip></el-table-column>
      -->
      <el-table-column prop="ifEnable" label="状态" width="80" header-align="center" align="center">
        <template slot-scope="scope">
          <div class="zwx-circle-text zwx-circle-normal" v-if="scope.row.ifEnable">启用</div>
          <div class="zwx-circle-text zwx-circle-finish" v-else>停用</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" min-width="140" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable == 1" @click="viewDetail(scope.row)">查看</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable == 0" @click="edit(scope.row)">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable == 0" @click="changeState(scope.row, 1)">启用</el-button>
          <el-button class="zwx-button zwx-button-text-26 el-button--danger" type="text" v-if="scope.row.ifEnable == 1" @click="changeState(scope.row, 0)">停用</el-button>
          <el-button class="zwx-button zwx-button-text-26 el-button--danger" type="text" v-if="scope.row.ifEnable == 0" @click="deleteGoods(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器部分 -->
    <base-pagination :parentPage="msForm.currentPage" :pageSize="msForm.pageSize" :total="msForm.total" @currentChange="queryShelveGoodsList" />
  </div>
</template>

<script>
export default {
  name: 'TrainingInstitutionManagement',
  data() {
    return {
      api: this.$store.state.api,
      zoneCode12From: '',
      msForm: {
        zoneCodeLike: '',
        unitNameLike: '',
        ifEnable: [1],
        currentPage: 1,
        tableList: [],
        pageSize: 18,
        total: 0,
      },
      // goodsTypeSimCodeList: [],
    }
  },
  activated() {
    if (this.$route.params.fresh) this.queryShelveGoodsList(1)
  },
  mounted() {
    // this.search(1)
    // 赋值当前用户所属地区
    let config = window.top.vm.$store.state.config
    this.zoneCode12From = config.empower //'320200000000'
    this.$nextTick(() => {
      this.$refs.bizZoneArea.initSearch(this.zoneCode12From)
      this.$forceUpdate()
    })
  },
  methods: {
    areaIdLoadOver() {
      this.queryShelveGoodsList(1)
    },
    bizZoneChange() {
      this.msForm.zoneCodeLike = this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    dateFormatter(row, column, cellValue) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
    queryShelveGoodsList(currentPage) {
      this.msForm.currentPage = currentPage
      this.msForm.zoneCodeLike = this.$refs.bizZoneArea.getChooseArea().zoneCode12
      let data = {
        currentPage,
        zoneCodeLike: this.msForm.zoneCodeLike,
        unitNameLike: this.msForm.unitNameLike,
        ifEnable: this.msForm.ifEnable.length === 1 ? this.msForm.ifEnable[0] : undefined,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/training/platform/getTrainingAgencyList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.msForm.tableList = data.trainingAgencyList
            this.msForm.pageSize = data.pageSize
            this.msForm.total = data.trainingAgencyCount
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    openDialog(row) {
      this.$refs.merchantCreateDialog.setShow(row)
    },
    add() {
      this.$router.push({ name: 'trainingInstitutionManagement.create', params: { type: 'add' } })
    },
    edit(row) {
      this.$router.push({ name: 'trainingInstitutionManagement.edit', params: { uuid: row.uuid, type: 'edit' } })
    },
    viewDetail(row) {
      this.$router.push({ name: 'trainingInstitutionManagement.detail', params: { uuid: row.uuid } })
    },
    changeState(row, ifEnable) {
      let tips = ifEnable === 0 ? '停用' : '启用'
      let url = '/training/platform/updateTrainingAgencyState-1'
      this.$system.msgbox('', '提示', '是否确定' + tips + '？', '确定', '取消', () => {
        let data = {
          uuid: row.uuid,
          adminUid: row.adminUid,
          ifEnable,
        }
        this.$emit('loading', true)
        this.$system.post(
          this.api + url,
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', tips + '成功', 'success')
              this.queryShelveGoodsList(1)
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      })
    },
    deleteGoods(row) {
      this.$system.msgbox('', '提示', '是否确定删除？', '确定', '取消', () => {
        let data = {
          uuid: row.uuid,
          adminUid: row.adminUid,
        }
        this.$emit('loading', true)
        // eslint-disable-next-line no-debugger
        this.$system.post(
          this.api + '/training/platform/deleteTrainingAgency-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.queryShelveGoodsList(1)
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .zwx-input {
  width: 220px !important;
}
</style>
