<template>
  <div class="staff-contact-history">
    <base-headline title="体检记录" class="no-top no-bottom"></base-headline>
    <el-table class="zwx-table sortable-table" style="width: 100%;margin-top:-1px" row-key="rid" key="msFormTable" :data="peRecordList" stripe border tooltip-effect="light">
      <el-table-column label="体检日期" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ dateFormatter(scope.row.peDate)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="peCode" label="体检编号" width="150" header-align="center" align="center" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.peCode || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="organName" label="体检机构" width="280" header-align="center" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.organName || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="postState " label="在岗状态" min-width="60" header-align="center" align="left">
        <template slot-scope="scope">
          <span>{{ postStateFormatter(scope.row.postState)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="危害因素" header-align="left" align="left" fixed="right" :min-width="160">
        <template slot-scope="scope">
          <span>{{ scope.row.hazardName || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="主检结论" width="240" header-align="center" align="left">
        <template slot-scope="scope" >
          <span v-for="(item,index) in scope.row.mainInspectionConclusionList" :key="item" :style="mainInspectionColor(item)">{{item}}<i style="color:black" v-if="scope.row.mainInspectionConclusionList.length != 1 && index!=scope.row.mainInspectionConclusionList.length-1">、</i></span>
        </template>
      </el-table-column>
      <el-table-column prop="unitName " label="单位名称" min-width="160" header-align="center" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.unitName || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="recheckMark " label="是否复检" min-width="60" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{recheckMarkFormatter(scope.row.recheckMark)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" header-align="left" align="left" fixed="right" :min-width="80">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'RecordListPage',
  props: {
    staffUid: { type: String, default: '' },
    cardNo: { type: String, default: '' },
  },
  data() {
    return {
      api: this.$store.state.api,
      peRecordList: [],
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    if(this.$zwxBase.verifyIsNotBlank(this.cardNo)){
      this.getPeRecordListByCardNo()
    }else{
      this.getPeRecordList()
    }
   },
  methods: {
    /**
     * 根据员工
     * */
    getPeRecordList() {
      let data = {
        staffUid: this.staffUid,
      }
      this.$system.get(
        this.api + '/oh/health/surveillance/getTdPeRecordListByStaffUid-1',
        data,
        true,
        true,
        data => {
          if (data.type == '00') {
            this.peRecordList = data.peRecordExpList || []
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
        /**
     * 根据员工
     * */
     getPeRecordListByCardNo() {
      let data = {
        cardNo: this.cardNo,
      }
      this.$system.get(
        this.api + '/oh/health/surveillance/getTdPeRecordListByCardNo-1',
        data,
        true,
        true,
        data => {
          if (data.type == '00') {
            this.peRecordList = data.peRecordExpList || []
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 是否复检
     */
     recheckMarkFormatter(value) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (value == true) {
          return '是'
        } else {
          return '否'
        }
      }
      return '--'
    },

    /**
     * 在岗状态
     */
     postStateFormatter(value) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (value == '30491001') {
          return '岗前'
        } else if (value == '30491002') {
          return '在岗'
        }else if (value == '30491003') {
          return '离岗时'
        }else if (value == '30491004') {
          return '离岗后'
        }else if (value == '30491005') {
          return '应急健康检查'
        }else if (value == '30491006') {
          return '事故照射'
        }else if (value == '30491007') {
          return '应急照射'
        }
      }
      return '--'
    },
    /**
     * 日期格式化
     */
     dateFormatter(cellValue) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
    mainInspectionColor(mainInspection){
      if (this.$zwxBase.verifyIsNotBlank(mainInspection)) {
        if(mainInspection == '职业禁忌证'){
           return 'color:#9719BB'
        }
        if(mainInspection == '疑似职业病'){
           return 'color:#FF1A21'
        }
      }
      return '-'
    },
   /**
     * 查看体检记录详情
     */
     detail(row) {
      this.$router.push({
        name: 'PeRecordDetail',
        params: { employerUuid: row.employerUuid, rid: row.rid },
      })
    }, 
  },
}
</script>

<style lang="less" scoped></style>
<style>
.zwx-staff-detection-report-detail .zwx-table th {
  border-top: unset !important;
}
</style>
