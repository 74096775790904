var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "staff-contact-history" },
    [
      _c("base-headline", {
        staticClass: "no-top no-bottom",
        attrs: { title: "体检记录" }
      }),
      _c(
        "el-table",
        {
          key: "msFormTable",
          staticClass: "zwx-table sortable-table",
          staticStyle: { width: "100%", "margin-top": "-1px" },
          attrs: {
            "row-key": "rid",
            data: _vm.peRecordList,
            stripe: "",
            border: "",
            "tooltip-effect": "light"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "体检日期",
              width: "120",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.dateFormatter(scope.row.peDate)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "peCode",
              label: "体检编号",
              width: "150",
              "header-align": "center",
              align: "center",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.peCode || "--"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "organName",
              label: "体检机构",
              width: "280",
              "header-align": "center",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.organName || "--"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "postState ",
              label: "在岗状态",
              "min-width": "60",
              "header-align": "center",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.postStateFormatter(scope.row.postState))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "危害因素",
              "header-align": "left",
              align: "left",
              fixed: "right",
              "min-width": 160
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.hazardName || "--"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "主检结论",
              width: "240",
              "header-align": "center",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(
                    scope.row.mainInspectionConclusionList,
                    function(item, index) {
                      return _c(
                        "span",
                        { key: item, style: _vm.mainInspectionColor(item) },
                        [
                          _vm._v(_vm._s(item)),
                          scope.row.mainInspectionConclusionList.length != 1 &&
                          index !=
                            scope.row.mainInspectionConclusionList.length - 1
                            ? _c("i", { staticStyle: { color: "black" } }, [
                                _vm._v("、")
                              ])
                            : _vm._e()
                        ]
                      )
                    }
                  )
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "unitName ",
              label: "单位名称",
              "min-width": "160",
              "header-align": "center",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.unitName || "--"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "recheckMark ",
              label: "是否复检",
              "min-width": "60",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.recheckMarkFormatter(scope.row.recheckMark))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "header-align": "left",
              align: "left",
              fixed: "right",
              "min-width": 80
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { type: "text", icon: "el-icon-arrow-right" },
                        on: {
                          click: function($event) {
                            return _vm.detail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }